<template>
  <header
    :class="{ active: scrollY > 300 }"
    class="iv2-global theme__global-bg"
  >
    <div class="iv2-global__items">
      <h1 class="iv2-global__logo theme__global-logo-bg">
        <router-link
          v-if="information.sp_logo_image_header"
          :to="'/' + lang + query"
          class="iv2-global__link"
        >
          <picture class="d-none d-md-inline">
            <source
              type="image/webp"
              :srcset="
                information.logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
          <picture class="d-md-none">
            <source
              type="image/webp"
              :srcset="
                information.sp_logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.sp_logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
        </router-link>
        <router-link v-else :to="'/' + lang + query" class="iv2-global__link">
          <picture>
            <source
              type="image/webp"
              :srcset="
                information.logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
        </router-link>
      </h1>
      <div class="iv2-global__menu d-xl-none d-flex text-center m-auto">
        <nav class="iv2-global__sub-menu d-none d-md-flex d-xl-none h-100">
          <div
            v-for="subnav in extra"
            :key="subnav.id"
            class="iv2-global__sub-menu-item theme__global-sns-link text-center d-inline-block"
          >
            <div v-if="subnav.link_type == 'internal'">
              <router-link
                :to="subnav.link_href + lang + query"
                :target="subnav.target_type"
                @click.native="closeMenu"
                class="iv2-global__sub-menu-link text-black"
              >
                <i
                  class="iv2-global__sub-menu-item-icon d-block"
                  :class="subnav.item_icon"
                ></i>
                <span class="iv2-global__sub-menu-item-text d-block">{{
                  subnav.item_name
                }}</span>
              </router-link>
            </div>
            <div v-else-if="subnav.link_type == 'external'">
              <a
                :href="subnav.link_href"
                :target="subnav.target_type"
                @click="closeMenu"
                rel="noopener"
                class="iv2-global__sub-menu-link text-black"
              >
                <i
                  class="iv2-global__sub-menu-item-icon d-block"
                  :class="subnav.item_icon"
                ></i>
                <span class="iv2-global__sub-menu-item-text d-block">{{
                  subnav.item_name
                }}</span>
              </a>
            </div>
          </div>
        </nav>

        <div
          @click="touchHandler"
          class="iv2-global__btn theme__global-btn d-inline-block"
        >
          <div
            :class="{ active: activeMenu }"
            class="iv2-global__btn-icon position-relative p-0"
          >
            <span class="iv2-global__top-drawer theme__global-icon"></span>
            <span class="iv2-global__center-drawer theme__global-icon"></span>
            <span class="iv2-global__bottom-drawer theme__global-icon"></span>
          </div>
          <span class="iv2-global__menu-text theme__global-text d-block">{{
            menuText
          }}</span>
        </div>
      </div>
    </div>

    <nav
      :class="{ active: activeMenu }"
      class="iv2-nav theme__nav-bg theme__nav-text"
    >
      <div class="iv2-nav__block">
        <div class="iv2-nav__header d-none d-xl-flex">
          <div
            v-if="organization.tel_number"
            class="iv2-nav__header-tel theme__nav-tel d-flex mr-3"
          >
            <span
              class="mt-auto mr-2"
              style="
                margin-bottom: 10px;
                font-family: 'Hiragino Maru Gothic Pro';
              "
              >{{ $store.state.themeLang.general.tel }}</span
            >
            <p class="iv2-nav__header-tel--text size-32 m-0">
              {{ organization.tel_number }}
            </p>
          </div>
          <div
            v-if="organization.opening_hours"
            class="iv2-nav__header-time mr-3"
          >
            <p
              v-html="organization.opening_hours"
              class="size-12 m-0"
              style="line-height: 1.5"
            ></p>
          </div>
          <div class="iv2-nav__header-address mr-3">
            <p
              v-if="organization.free_text"
              v-html="organization.free_text"
              class="size-12 m-0"
              style="line-height: 1.3"
            ></p>
            <div style="line-height: 1.3">
              <span v-if="organization.zip_code" class="size-12 m-0">{{
                "〒" + organization.zip_code
              }}</span>
              <span v-if="organization.prefecture_name" class="size-12 m-0">{{
                organization.prefecture_name
              }}</span>
              <span v-if="organization.city_name" class="size-12 m-0">{{
                organization.city_name
              }}</span>
              <span v-if="organization.town_name" class="size-12 m-0">{{
                organization.town_name
              }}</span>
              <span v-if="organization.building_name" class="size-12 m-0">{{
                organization.building_name
              }}</span>
            </div>
          </div>
        </div>
        <ul class="iv2-nav__list list-unstyled">
          <li
            v-for="nav in global"
            :key="nav.id"
            class="iv2-nav__item theme__nav-item"
            :class="{
              selected: activeNav === nav,
              active: activeParent == nav.id,
            }"
            @mouseover="activeNav = nav"
            @mouseleave="activeNav = ''"
          >
            <router-link
              v-if="nav.link_type == 'internal'"
              :to="nav.link_href + lang + query"
              @click.native="closeMenu"
              :target="nav.target_type"
              :class="{
                'iv2-nav__link-parent': nav.children.length > 0,
              }"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</router-link
            >
            <a
              v-else-if="nav.link_type == 'external'"
              :href="nav.link_href"
              @click="closeMenu"
              :target="nav.target_type"
              :class="{
                'iv2-nav__link-parent': nav.children.length > 0,
              }"
              rel="noopener"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</a
            >
            <a
              v-if="nav.children.length > 0"
              @click="activeList(nav.id)"
              class="iv2-nav__acordion theme__nav-acordion d-inline-block d-xl-none position-relative"
              href="javascript:void(0)"
            ></a>
            <ul
              v-if="nav.children.length > 0"
              :class="[navChildColor, setHeight(nav.children.length)]"
              class="iv2-nav__list list-unstyled"
            >
              <li
                v-for="cNav in nav.children"
                :key="cNav.id"
                class="iv2-nav__item theme__nav-item--inner"
              >
                <router-link
                  v-if="cNav.link_type == 'internal'"
                  :to="cNav.link_href + lang + query"
                  @click.native="closeMenu"
                  :target="cNav.target_type"
                  class="iv2-nav__link theme__nav-link--inner"
                  >{{ cNav.item_name }}</router-link
                >
                <a
                  v-else-if="cNav.link_type == 'external'"
                  :href="cNav.link_href"
                  @click="closeMenu"
                  :target="cNav.target_type"
                  rel="noopener"
                  class="iv2-nav__link theme__nav-link--inner"
                  >{{ cNav.item_name }}</a
                >
              </li>
            </ul>
          </li>
        </ul>

        <div class="iv2-nav__footer">
          <div class="iv2-nav__footer-item">
            <div
              v-if="organization.tel_number"
              class="iv2-nav__footer-tel d-inline-flex"
            >
              <p class="size-14 m-0 mr-1">
                {{ $store.state.themeLang.general.tel }}
              </p>
              <p class="iv2-nav__footer-tel--text size-14 m-0">
                {{ organization.tel_number }}
              </p>
            </div>

            <div v-if="organization.opening_hours" class="iv2-nav__footer-time">
              <p
                v-html="organization.opening_hours"
                class="size-14 my-2"
                style="line-height: 1.3"
              ></p>
            </div>
            <p
              v-if="organization.free_text"
              v-html="organization.free_text"
              class="size-14 my-2"
              style="line-height: 1.5"
            ></p>
            <p
              v-if="organization.zip_code"
              class="size-14 m-0"
              style="line-height: 1.3"
            >
              {{ "〒" + organization.zip_code }}
            </p>
            <div class="iv2-nav__footer-adress p-0" style="line-height: 1.3">
              <span v-if="organization.prefecture_name" class="size-14 m-0">{{
                organization.prefecture_name
              }}</span>
              <span v-if="organization.city_name" class="size-14 m-0">{{
                organization.city_name
              }}</span>
              <span v-if="organization.town_name" class="size-14 m-0">{{
                organization.town_name
              }}</span>
              <span v-if="organization.building_name" class="size-14 m-0">{{
                organization.building_name
              }}</span>
            </div>
          </div>
          <div class="d-block d-xl-none text-center mt-4">
            <div class="iv2-nav__mobile-sns m-auto">
              <ul class="iv2-nav__mobile-sns-list mb-0 list-unstyled">
                <li
                  v-if="sns.is_linked_facebook == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.facebook_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li
                  v-if="sns.is_linked_instagram == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.instagram_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li
                  v-if="sns.is_linked_twitter == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.twitter_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li
                  v-if="sns.is_linked_line == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.line_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-line"></i>
                  </a>
                </li>
                <!-- <li
                  v-if="rss.is_linked_blog == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="rss.url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fas fa-rss"></i>
                  </a>
                </li> -->
              </ul>
            </div>
            <div v-if="organization.copy_right" class="iv2-nav__copyright">
              <small>{{ organization.copy_right }}</small>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- フリーページ項目に対応した動的height値指定 -->
    <div is="style">
      @media (min-width: 1200px) {
      {{ setHeight() }}
      }
    </div>
    <!-- フリーページ項目に対応した動的height値指定 -->
  </header>
</template>

<script>
export default {
  props: {
    global: Array,
    extra: Array,
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
    count: Number,
  },

  data() {
    return {
      menuText: "",
      query: "",
      lang: "",
      activeMenu: true,
      activeParent: false,
      activeNav: "",
      navColorClass: "",
      navChildColor: "",
      scrollY: 0,
      environment: "",
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        if (window.innerWidth <= 1199) {
          this.activeMenu = false;
          this.activeParent = false;
          this.menuText = "MENU";
        }
        const el = document.body;
        el.classList.remove("noscroll");
      }
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.menuText = "MENU";

    if (window.innerWidth <= 1199) {
      this.activeMenu = false;
    } else {
      this.activeMenu = true;
    }
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    activeList(key) {
      if (this.activeParent === key) {
        this.activeParent = null;
      } else {
        this.activeParent = key;
      }
    },

    handleScroll() {
      this.scrollY = window.scrollY;
    },

    handleResize() {
      if (window.innerWidth >= 1199) {
        this.navColorClass = "theme__global-bg";
      } else {
        this.navColorClass = "theme__nav-bg";
      }

      if (window.innerWidth >= 1199) {
        this.navChildColor = "theme__nav-bg";
      } else {
        this.navChildColor = "";
      }
    },

    touchHandler() {
      this.activeMenu = !this.activeMenu;

      if (this.activeMenu == true) {
        const el = document.body;
        el.classList.add("noscroll");
        this.menuText = "CLOSE";
      } else {
        const el = document.body;
        el.classList.remove("noscroll");
        this.menuText = "MENU";
      }
    },

    closeMenu() {
      if (window.innerWidth <= 1199) {
        this.activeMenu = false;
        this.activeParent = false;
        const el = document.body;
        el.classList.remove("noscroll");
        this.menuText = "MENU";
      }
    },

    setHeight(cnt = 0) {
      return cnt > 10 ? "iv2-global__nav-high" : "";
    },
  },
};
</script>

<style lang="scss">
.iv2-global {
  &.active {
    .iv2-global__items {
      .iv2-global__logo {
        .iv2-global__logo-img {
          max-width: 75%;
          max-height: 72%;
        }
      }
    }
  }
  .iv2-global__items {
    .iv2-global__logo {
      margin: auto;
      padding: 5px 10px;
      position: relative;
      z-index: 11;
      -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

      .iv2-global__logo-img {
        max-width: 80%;
        max-height: 72%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition: 0.3s;
      }
    }

    .iv2-global__menu {
      top: 50%;
      left: 0;
      right: 0;

      .iv2-global__btn {
        .iv2-global__btn-icon {
          width: 30px;
          height: 25px;
          margin: 0 auto;
          cursor: pointer;

          .iv2-global__top-drawer,
          .iv2-global__center-drawer,
          .iv2-global__bottom-drawer {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            margin: auto;
            right: 0;
            left: 0;
            transition: 0.3s;
          }

          .iv2-global__top-drawer {
            top: 2px;
          }

          .iv2-global__center-drawer {
            top: 0;
            bottom: 0;
            visibility: visible;
            opacity: 1;
          }

          .iv2-global__bottom-drawer {
            bottom: 2px;
          }

          &.active {
            .iv2-global__top-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(-45deg);
            }

            .iv2-global__center-drawer {
              visibility: hidden;
              opacity: 0;
            }

            .iv2-global__bottom-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(45deg);
            }
          }

          &:focus {
            outline: 0;
          }
        }
      }

      .iv2-global__menu-text {
        font-size: 10px;
        font-weight: 400;
        line-height: 1;
      }
    }
  }

  .iv2-nav {
    width: 100%;
    z-index: 1;
    visibility: hidden;
    display: none;

    &.active {
      visibility: visible;
      display: block;
    }

    .iv2-nav__block {
      > .iv2-nav__list {
        display: inline-block;
        vertical-align: top;

        .iv2-nav__item {
          font-size: 16px;
          letter-spacing: 2px;

          .iv2-nav__link {
            &:hover {
              text-decoration: none;
            }
          }

          > .iv2-nav__list {
            padding-left: 20px;
            padding-left: 0;

            .iv2-nav__item {
              font-size: 16px;
            }
          }
        }
      }
    }

    .iv2-nav__footer {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  @keyframes tooltipShow {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fade-move {
    0% {
      transform-origin: 0% 0;
      transform: translate3d(0%, 0%, 0px) scale(1, 0);
    }

    100% {
      transform-origin: 0% 0;
      transform: translate3d(0%, 0%, 0px) scale(1, 1);
    }
  }

  .iv2-global {
    display: flex;
    transition: 0.3s;

    &.active {
      .iv2-global__items {
        height: 110px;
      }

      .iv2-nav {
        .iv2-nav__block {
          .iv2-nav__header {
            height: 0;
            opacity: 0;
          }

          > .iv2-nav__list {
            height: 100%;

            .iv2-nav__item {
              .iv2-nav__link {
                padding: 38px 25px 0;
              }

              .iv2-nav__link-parent {
                padding-right: 30px;
              }
            }
          }
        }
      }
    }

    .iv2-global__items {
      width: 370px;
      height: 207px;
      transition: 0.3s;

      .iv2-global__logo {
        width: 100%;
        height: 100%;
      }
    }

    .iv2-nav {
      height: 100%;

      .iv2-nav__block {
        height: 100%;

        .iv2-nav__header {
          height: 50%;
          align-items: center;
          justify-content: flex-end;
          transition: 0.3s;

          .iv2-nav__header-tel {
            .iv2-nav__header-tel--text {
              font-weight: 600;
              font-family: Arial, Helvetica, sans-serif;
            }
          }
        }

        > .iv2-nav__list {
          display: flex;
          height: 50%;
          margin: 0;
          align-items: center;
          justify-content: flex-end;
          transition: 0.3s;

          .iv2-nav__item {
            font-size: 14px;
            line-height: 2.6;
            height: 100%;
            letter-spacing: 0;
            white-space: nowrap;
            position: relative;

            .iv2-nav__link {
              padding: 10px 25px 0;
              display: block;
              position: relative;
              height: 100%;
              transition: 0.3s;
            }

            .iv2-nav__link-parent {
              padding-right: 30px;

              &::before {
                content: "";
                width: 5px;
                height: 5px;
                border-left: 2px solid;
                border-bottom: 2px solid;
                margin: 0;
                display: block;
                position: absolute;
                right: 13px;
                top: 50%;
                transform: translateY(-50%) rotate(-45deg);
              }
            }

            &:last-child {
              > .iv2-nav__list {
                right: 0;
              }
            }

            > .iv2-nav__list.iv2-global__nav-high {
              height: 80vh;
              overflow-y: scroll;
              overflow-x: hidden;
            }

            > .iv2-nav__list {
              height: auto;
              width: 230px;
              text-align: center;
              position: absolute;
              top: 100%;
              visibility: hidden;
              transition: 0.5s;
              opacity: 0;
              -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
              box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);

              .iv2-nav__item {
                font-size: 16px;
                height: auto;

                &:last-child {
                  border-bottom: 0;
                }

                .iv2-nav__link {
                  padding: 10px;
                  width: 210px;
                  display: block;
                  white-space: break-spaces;
                }
              }
            }

            &.selected {
              > .iv2-nav__list {
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }

      .iv2-nav__footer {
        display: none;
      }
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  @keyframes tooltipShow {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }

    to {
      opacity: 1;
    }
  }

  .iv2-global {
    &.active {
      .iv2-global__items {
        .iv2-global__logo {
          height: 74px;
        }
      }
    }
    .iv2-global__items {
      height: 100%;

      .iv2-global__logo {
        display: inline-block;
        width: 200px;
        height: 112px;
        transition: 0.3s;

        .iv2-global__link {
          margin-bottom: 0;
          height: 100%;
          display: block;
        }
      }

      .iv2-global__menu {
        height: 100%;
        float: right;

        .iv2-global__btn {
          line-height: 1;
        }

        .iv2-global__menu-text {
          margin-top: 8px;
        }
      }
    }

    .iv2-nav {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 74px;
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;

      .iv2-nav__sub-menu-item {
        font-size: 1.75em;
        border-right: 1px solid;

        &:last-child {
          border-right: none;
        }
      }

      .iv2-nav__sub-menu-item-text {
        font-size: 0.5em;
        line-height: 1.3;
      }

      .iv2-nav__block {
        padding: 50px 20px 20px;
        animation: tooltipShow 0.5s linear 0s;

        .iv2-nav__list {
          display: block;
          margin: auto;
          padding: 0 64px;
          float: none;

          .iv2-nav__item {
            position: relative;
            display: block;
            font-size: 1em;
            margin: 0;

            .iv2-nav__link {
              display: block;
              padding: 15px 0;
            }

            .iv2-nav__link-parent {
              width: 80%;
            }

            > .iv2-nav__list {
              padding: 0;
              visibility: hidden;
              opacity: 0;
              height: 0;

              &.active {
                visibility: visible;
                opacity: 1;
                height: auto;
                transition: 0.5s;
              }
            }

            .iv2-nav__acordion {
              width: 27px;
              height: 45px;
              float: right;
              -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
              bottom: 15px;
              margin-right: 10px;

              &::before {
                content: "";
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translateX(50%) translateY(-50%) rotate(-45deg);
                transition: 0.2s;
              }

              &::after {
                content: "";
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translateX(50%) translateY(-50%) rotate(-45deg);
                transition: 0.2s;
              }
            }

            &.active {
              > .iv2-nav__list {
                visibility: visible;
                opacity: 1;
                height: auto;

                > .iv2-nav__item {
                  font-size: 1em;
                  padding-left: 20px;
                }
              }

              .iv2-nav__acordion {
                &::before {
                  right: 0;
                }

                &::after {
                  right: 100%;
                }
              }
            }
          }
        }
      }

      .iv2-nav__footer {
        padding: 1.5rem 4rem 6rem;
        margin-top: 2rem;

        .iv2-nav__footer-item {
          padding: 30px 60px;
        }
      }

      .iv2-nav__mobile-sns-list {
        .iv2-nav__mobile-sns-item {
          font-size: 1.1em;
          display: inline-block;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__menu {
        .iv2-global__btn {
          width: 74px;
          height: 100%;
          padding: 15px 10px;
        }

        .iv2-global__sub-menu {
          padding: 10px 0;
          align-items: center;

          .iv2-global__sub-menu-item {
            font-size: 1.5rem;
            width: 80px;
            padding: 0 10px;
            position: relative;

            .iv2-global__sub-menu-link {
              &:hover {
                text-decoration: none;
              }
            }

            .iv2-global__sub-menu-item-text {
              font-size: 10px;
              font-weight: 400;
              margin-top: 8px;
              line-height: 1.2;
            }
          }
        }
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-global {
    &.active {
      .iv2-global__items {
        .iv2-global__logo {
          height: 45px;
        }
      }
    }

    .iv2-global__items {
      .iv2-global__logo {
        width: 116px;
        height: 65px;
      }

      .iv2-global__menu {
        padding: 6px 10px;

        .iv2-global__menu-text {
          margin-top: 0;
        }

        .iv2-global__btn {
          .iv2-global__btn-icon {
            width: 25px;
            height: 20px;
            margin-bottom: 3px;
          }
        }
      }
    }

    .iv2-nav {
      margin-top: 45px;
      padding-bottom: 10rem;
      padding-bottom: calc(10rem + env(safe-area-inset-bottom));

      .iv2-nav__block {
        .iv2-nav__list {
          padding: 0 21px;

          .iv2-nav__item {
            .iv2-nav__link {
              padding: 10px 5px;
            }

            .iv2-nav__acordion {
              height: 35px;
            }
          }
        }
      }

      .iv2-nav__footer {
        padding: 20px 20px 100px;

        .iv2-nav__footer-item {
          padding: 15px 20px;
        }
      }
    }
  }
}
</style>
