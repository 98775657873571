<template>
  <section v-if="displayRss" class="iv2-rss theme__sub-bg">
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full text-center">
          <p class="m-0">{{ $store.state.publicLang.general.rss }}</p>
          <h3>
            <a
              :href="rss.link"
              class="theme__heading-text font-weight-bold"
              target="_blank"
              rel="noopener"
              >{{ rss.name }}</a
            >
          </h3>
        </div>
        <div class="column full">
          <div class="spacer height-40"></div>
        </div>
        <div
          v-for="item in rss.articles"
          :key="item.id"
          class="iv2-rss__item col-md-6"
        >
          <a
            :href="item.url"
            class="iv2-rss__item--link"
            target="_blank"
            rel="noopener"
          >
            <div class="iv2-rss__items--block theme__main-bg column full">
              <small>{{ item.created_at }}</small>
              <h4 class="iv2-rss__items-title">{{ item.title }}</h4>
              <p class="iv2-rss__items-summary mt-0 mb-2">
                {{ item.discription }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    rss: Object,
  },

  data() {
    return {
      displayRss: "",
    };
  },

  created() {
    if (this.rss.is_linked_blog === 0 || this.rss.articles.length === 0) {
      this.displayRss = false;
    } else {
      this.displayRss = true;
    }
  },
};
</script>

<style lang="scss">
.iv2-rss {
  padding: 80px 0 100px;

  .iv2-rss__item {
    margin: 15px 0;

    .iv2-rss__item--link {
      text-decoration: none;

      .iv2-rss__items--block {
        padding: 0.5rem 1rem;
        -webkit-box-shadow: 0px 0px 20px rgba(100, 100, 100, 0.1);
        box-shadow: 0px 0px 20px rgba(100, 100, 100, 0.1);
        height: 100%;

        &:hover {
          transition: 0.3s;
          transform: scale(1.01);
        }

        .iv2-rss__items-title {
          font-size: 22px;
        }

        .iv2-rss__items-summary {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }
}
</style>
