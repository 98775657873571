<template>
  <section
    v-if="
      items.is_open_news == 1 &&
      news.length > 0 &&
      params.limit > 0 &&
      indexOpenType == 1
    "
    class="iv2-section-news theme__sub-bg"
  >
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full text-center">
          <p class="m-0 font-weight-bold text-muted">{{ subtitle }}</p>
          <h3 class="theme__heading-text font-weight-bold">{{ title }}</h3>
        </div>
        <div class="column full">
          <div class="spacer height-40"></div>
        </div>
      </div>

      <div v-if="type == 'list'" class="row clearfix">
        <div class="column full">
          <NewsLine v-for="item in limitCount" :key="item.id" :content="item" />
        </div>
      </div>

      <div v-else-if="type == 'tile'" class="row clearfix">
        <div class="col-12">
          <div v-for="item in limitCount" :key="item.id" class="my-5">
            <NewsTile :content="item" />
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="column full">
          <div class="spacer height-40"></div>
        </div>

        <div class="column full">
          <div class="text-center">
            <span>
              <router-link
                :to="{
                  name: 'news',
                  query: Object.assign({}, $route.query),
                }"
                class="iv2-top-news-btn theme__main-btn is-btn is-btn-ghost2 is-upper size-18"
                >{{ $store.state.publicLang.page.news_button }}</router-link
              >
              <!-- <a
                v-if="rss.is_linked_blog == 1"
                :href="rss.url"
                target="_blank"
                rel="noopener"
                class="iv2-top-news-btn theme__main-btn is-btn is-btn-ghost2 is-upper size-18"
              >
                <i class="fas fa-rss"></i>{{ $store.state.publicLang.page.rss_button }}
              </a> -->
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NewsLine from "@/components/items/BaseNewsLine.vue";
import NewsTile from "@/components/items/BaseNewsTile.vue";

export default {
  components: {
    NewsLine,
    NewsTile,
  },

  props: {
    items: Object,
    rss: Object,
  },

  data() {
    return {
      news: [],
      title: "",
      subtitle: "",
      type: "",
      indexOpenType: "",
      params: {
        type: "news",
        limit: "",
      },
    };
  },

  computed: {
    limitCount() {
      return this.news.slice(0, this.params.limit);
    },
  },

  created() {
    this.params.limit = this.items.display_news_number;
  },

  mounted() {
    const newsRepository = this.$repository.get("news");
    const news = newsRepository.index(this.params);
    const self = this;
    news
      .then((result) => {
        const res = result.data.response;
        self.news = res.data;
        self.title = res.title;
        self.subtitle = res.subtitle;
        self.type = res.layout_type;
        if (self.$route.query.mode == "preview") {
          self.indexOpenType = 1;
        } else {
          self.indexOpenType = res.open_type;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style lang="scss">
.iv2-section-news {
  padding: 80px 0;

  .iv2-top-news-btn {
    border: 0;
    letter-spacing: 2px;
    max-width: 100%;
    width: 300px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 5px 15px;
  }
}
</style>
