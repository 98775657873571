<template>
  <transition name="scroll" appear>
    <div
      v-if="scrollY > 300"
      class="iv2-home__pagetop-bar theme__scroll position-fixed d-none d-xl-block text-center"
      @click="scrollTop"
    >
      <span class="iv2-home__pagetop-bar-text">{{
        $store.state.themeLang.general.page_top
      }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      scrollY: 0,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },

    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.iv2-home__pagetop-bar {
  right: 0px;
  bottom: 0px;
  width: 20px;
  height: 110px;
  margin: 0 30px;
  padding-top: 55px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  cursor: pointer;
  z-index: 1111;

  &::before {
    content: "";
    width: 13px;
    height: 13px;
    border-bottom: 1px solid;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 8px;
    top: -1px;
  }

  &::after {
    content: "";
    height: 40px;
    width: 1px;
    position: absolute;
    left: 5px;
    top: 5px;
  }

  .iv2-home__pagetop-bar-text {
    transform: rotate(90deg);
    display: block;
    width: 53px;
    padding-top: 33px;
    line-height: 1;
  }

  &.scroll-enter-active,
  &.scroll-leave-active {
    transition: all 1s ease;
  }

  &.scroll-enter,
  &.scroll-leave-to {
    transform: translateY(150px) !important;
    opacity: 0;
  }
}
</style>
